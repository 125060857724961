import React from "react"

const Icon: React.FC = () => (
    <svg width="26px" height="27px" viewBox="0 0 26 27" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="search-field" transform="translate(0.000000, -16.000000)">
                <g id="search-jcon" transform="translate(0.000000, 17.000000)">
                    <g transform="translate(14.477273, 14.181818) rotate(-45.000000) translate(-14.477273, -14.181818) translate(4.136364, -1.181818)">
                        <rect id="Rectangle" fill="#0D0D0D" x="9.75" y="20.6818182" width="1.18181818" height="10.0454545" rx="0.590909091"></rect>
                        <circle id="Oval" stroke="#0D0D0D" cx="10.3409091" cy="10.3409091" r="10.3409091"></circle>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Icon