import React from "react"

const Icon: React.FC = () => (
    <svg width="19px" height="15px" viewBox="0 0 19 15" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="button" transform="translate(-51.000000, -22.000000)" stroke="#0D0D0D">
                <g id="content" transform="translate(52.000000, 18.000000)">
                    <g id="printer-icon" transform="translate(0.000000, 4.000000)">
                        <polygon id="Rectangle" points="0 4.5 17 4.5 17 12.5 12 12.5 12 7.5 5 7.5 4.9113976 12.5 0 12.5"></polygon>
                        <rect id="Rectangle" x="2" y="0.5" width="13" height="4"></rect>
                        <rect id="Rectangle" x="5" y="7.5" width="7" height="7"></rect>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Icon