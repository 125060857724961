import React, { useState, useEffect } from "react"

import { fetchProducts } from "../services/google.service"

import { useWindowSize } from "../hooks/use-window-size"
import SearchIcon from "../icons/search"
import PrinterIcon from "../icons/printer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Field from "../components/field"
import Button from "../components/button"
import Loader from "../components/loader"
import ProductCell from "../components/product-cell"

import styles from "../styles/index.module.css"

const OurProducts: React.FC = () => {

  const { width } = typeof window !== 'undefined' ? useWindowSize() : { width: 900 }
  
  // State

  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState(null)

  // Effects

  useEffect(() => {
    fetchData()
  }, [])

  // Network 

  const fetchData = async () => {
    const data = await fetchProducts()
    console.log(data)
    setProducts(data)
    setFilteredProducts(data)
  }

  // Handlers

  const onSearchValueChange = (event) => {
    const query = event.target.value.toLowerCase()
    const array = products.filter(p => {
      return  p.number.toLowerCase().indexOf(query) > -1 ||
              p.titleFr.toLowerCase().indexOf(query) > -1 ||
              p.upc.toLowerCase().indexOf(query) > -1 ||
              p.format.toLowerCase().indexOf(query) > -1 
    })

    setFilteredProducts(array)
  }

  // Rendering

  const buildProductMatrix = () => {
    let matrix = [];

    let colCount = 4
    if (width >= 900 && width < 1200) {
      colCount = 3
    } else if (width >= 700 && width < 900) {
      colCount = 2
    } else if (width < 700 ) {
      colCount = 1
    }
    
    for (let i=0; i< colCount; i++) {
      matrix.push({
        id: Math.random(),
        products: []
      })
    }

    for (let i=0; i<filteredProducts.length; i++) {
      const colIndex = i%matrix.length
      matrix[colIndex].products.push(filteredProducts[i])
    }

    return matrix
  }

  const renderContent = () => {
    if (!filteredProducts) return <Loader/>

    const productMatrix = buildProductMatrix()

    return (
      <div>
        <div className={styles.toolbar}>
          <Field 
            onChange={onSearchValueChange}
            className={styles.searchField} 
            placeholder="Rechercher" 
            prefix={<SearchIcon/>}/>
          <a href="/print" target="_blank">
            <Button 
              icon={<PrinterIcon/>}>
              Imprimer
            </Button>
          </a>
        </div>
        
        <div className={styles.columnsContainer}>

          {productMatrix.map((col) => (
            <div className={styles.productColumn} key={col.id}>
              {col.products.map(p => (
                <ProductCell 
                  key={p.number}
                  img={p.imgUrl} 
                  title={`${p.number} ${p.titleFr}`}
                  upc={p.upc} 
                  format={p.format}
                  available={p.available}/>
              ))}
            </div>  
          ))}
          
        </div>
      </div>
    )
  }

  return (
    <Layout location="/"> 
      <SEO title="KPAJ - Nos produits" />
      <div className={styles.header}>
        <p>
          Nous avons <strong>des planches à découper pour toutes les occasions</strong>.
        </p>
      </div>
      <div className={styles.title}>
        <h1>Nos produits</h1>
      </div>
      <div className={`container`}>
        {renderContent()}
      </div>
    </Layout>
  )
}

export default OurProducts