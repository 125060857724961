import React from "react"

import styles from "../styles/product-cell.module.css"

interface Props {
    img: string
    title: string
    upc: string
    format: string
    available: boolean
}

const ProductCell = ({ img, title, upc, format, available }: Props) => (
  <div className={`${styles.cell} ${!available ? styles.unavailable : ""}`}>
    <img src={img}/>
    <p>
      <strong>{!available && "NON DISPONIBLE - "}{title}</strong><br/>
      {upc}<br/>
      {format}
    </p>
  </div>
)

export default ProductCell
