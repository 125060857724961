import React from "react"

import LoadingIcon from "../icons/loading"

import styles from "../styles/button.module.css"

interface Props {
    onClick?: () => void
    className?: string,
    children?: React.ReactNode
    icon?: React.ReactNode
    loading?: boolean
    disabled?: boolean
}

const Button = ({ disabled = false, loading = false, children, className, icon, onClick } : Props) => (
  <button disabled={disabled || loading} onClick={onClick} className={`${styles.button} ${className ? className : ''}`}>
      { loading && <div className={styles.loadingIconHolder}>{<LoadingIcon/>}</div>}
      { (icon && !loading) && <div className={styles.iconHolder}>{icon}</div> }
      {children}
  </button>
)

export default Button
