import React, { ChangeEvent } from "react"

import styles from "../styles/field.module.css"

interface Props {
    placeholder?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    className?: string,
    prefix?: React.ReactNode
}

const Field = ({ placeholder, className, prefix, onChange } : Props) => (
  <div className={`${styles.holder} ${className ? className : ''}`}>
      { prefix && <div className={styles.prefixHolder}>{prefix}</div> }
      <input className={prefix ? styles.hasPrefix : ''} placeholder={placeholder} onChange={onChange} />
      <div className={styles.bottomBorder}/>
  </div>
)

export default Field
